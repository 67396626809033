<template>
    <!-- <Header></Header>
  <Menu></Menu> -->
<div class="page-wrapper">
    <div class="container-fluid position-relative">
      <div class="row page-titles">
        <div class="col-md-5 align-self-center"></div>
        <div class="col-md-7 align-self-center text-end">
          <div class="d-flex justify-content-end align-items-center">
            <ol class="breadcrumb justify-content-end">
              <li class="fw-bold h3"><span>Enregistrer le rapport de vente du jour</span></li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <section class="spinnerWindow" v-show="spinner">
      <div class="spinner" >
        <div class="spinner-border text-warning" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
     </section>

     <div class="form-body">
      <form  @submit.prevent="send_rapport">
     
      <div class="card-body">
        <div class="row pt-3">
         
          <!--/span-->
          <div class="col-md-6">
              <div class="form-group">
                <label class="form-label fw-bold">Choisir le nom du package</label>
               
                <select class="w-100 form-group" v-model="type_id" id="type"
                 @change="selectIdPackage">
                  <option disabled value="">---Packages---</option>
                  <option
                    v-for="type in list_packages"
                    :key="type.id"
                    :value="type.id"
                  >
                    {{ type.libelle }}
                  </option>
                </select>
                <span class="d-block text-danger" v-if="type_id ">Pièces restantes : {{numberOfPieceReste}}</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label fw-bold">Nombre total de pièces</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="le nombre total de pièces vendus (ex:500)"
                  v-model="nb_vente"
                  required
                  pattern="^([0-9]*)$"
                />
              </div>
            </div>
            
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label fw-bold">Pièce jointe</label>
                <input
                  type="file"
                  class="form-control"
                  @change.prevent="handleMedias"
                />
              </div>
            </div>
          <!--/span-->
        </div>
      </div>      
        <div class="card-body">
          <button
            type="submit"
            class="btn text-white mx-3 btn-envoyer"
           :disabled="
              type_id === null
            ">
            Enregistrer
          </button>
          <button @click="$router.go(-1)" type="button" class="btn btn-dark">
            Annuler
          </button>
      </div>
      </form>
    </div>

    
</div>
</template>
<script>
import Swal from "sweetalert2";

import axios from "axios";
import { lien } from "/src/assets/api.js";
export default {
    name:'saveRapportventes',
    components: {
        // Header,Menu,Footer,
    },
    data() {
     return{
         list_packages:[],
         nb_vente:null,
         type_id:"",
         user:this.$store.state.user,
         spinner:false,
         proofPieces:"",
         numberOfPieceReste:"",
     }
    },
    methods: {
      handleMedias(files) {
      // let pictureAtUpload = files
      this.proofPieces = files.target.files[0];
      //console.log("Photo de pièces", files.target.files[0]);
    },
      selectIdPackage(e){

  this.list_packages.forEach(item=>{
    if(item.id == e.target.value){
      this.numberOfPieceReste = item.pieces_restantes
     
    }
    
  })
      },
         get_Package(){
       axios.get(lien+"/api/souscris/"+this.user.id,
    { headers: {
            'Authorization':"Bearer "+this.$store.state.token,
          },})
    .then(reponse =>{
        // console.log("POSTTYPEPACKAGE",reponse); 
        this.list_packages = reponse.data.data
    })
    .catch(error=>{
        console.log(error);
    })

    },
    send_rapport(){
      this.spinner = true
     

      let data = new FormData();
      
      data.append("nb_ventes", this.nb_vente);
      data.append('package_id',this.type_id)
      data.append("proof", this.proofPieces);


    axios.post(lien+"/api/ventes",data)
       .then(reponse =>{
       
        if(reponse.data.status === 'true'){
             Swal.fire({
              text: "Rapport envoyé",
              icon: "success",
              showConfirmButton: true,
              timer: 1500,
              
            })
            this.nb_vente =" "
            this.type_id =" "
            this.proofPieces = ""
           setTimeout(()=>{
              this.$router.push("/list-rapport-ventes")
           },1500)
          this.spinner = false
        }
        if(reponse.data.status === false || reponse.data.status === 'false'){
          Swal.fire({
              text: reponse.data.message,
              icon: "info",
              showConfirmButton: true,
              
              
            })
            this.spinner = false
        }
    })
    .catch(error=>{
        console.log(error);
           Swal.fire({
              text: "Contactez votre service informatique",
              icon: "info",
              showConfirmButton: true,
              timer: 2500,
              
            });
            this.spinner = false
    })
    },
    },
    created(){
        this.get_Package()
    }
}
</script>
<style scoped>
.spinner{
  background-color:rgb(27, 27, 27) !important;
  width:45px;
  height:45px;
  padding:.5em 0 0 0 ;
  border-radius:5px;
}
.spinnerWindow {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(103, 101, 101, 0.295);
  z-index: 99;
  display: flex;
  place-items: center;
  justify-content: center;
}
select {
  padding: 0.589em !important;
  border-radius: 0.25rem;
}
select:active {
  border: 1px solid rgb(134, 134, 134);
}
.my_footer{
position:relative;
width:100%;
bottom:-20em;
margin-left: 0 !important;
}
.btn-envoyer{
background: rgb(231, 202, 15) !important;
color:black !important;
border:3px solid black !important;
}
input,select{ 
  border: 1px solid black !important;
}
.form-group{ 
  text-align: left !important;
}


</style>